import React from 'react'
import style from '../styles/Footer.module.scss'
import SocialNavbar from './SocialNavbar'
import InstagramFeed from './InstagramFeed'
import navbarStyle from '../styles/Navbar.module.scss'

function Footer() {
    return (
        <footer className={style.footerStyle}>
            <InstagramFeed />
            <SocialNavbar style={navbarStyle.socialNavbar}/>
            <p className={style.copyright}>Copyright © 2019 by IR</p>
        </footer>
    )
}
export default Footer