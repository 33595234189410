import React, { useCallback, useEffect, useState } from 'react';
import style from '../styles/Navbar.module.scss'
import SocialNavbar from './SocialNavbar';
import Navbar from './Navbar';
import { useScrollLock } from '../hooks/ScrollLock';

function MobileNavbar({visible} : {visible:boolean}) {
    const [mobileMenuActive, setMobileMenuActive] = useState(!visible);
    const { lockScroll } = useScrollLock();

    const onClick = useCallback(
        () => {
            setMobileMenuActive(!mobileMenuActive);
            lockScroll(!mobileMenuActive);
        },
        [mobileMenuActive],
    );

    useEffect(()=>{
        return () => {
            setMobileMenuActive(false);
            lockScroll(false);
        }
    },[]);

    return (
        <div style={visible ? { visibility: 'visible'} : { visibility: 'hidden'}} className={style.mobileMenuWrapper}>
            <div onClick={onClick} className={style.burger_icon}>
                <div className={style.burger_line} />
                <div className={style.burger_line} />
                <div className={style.burger_line} />
            </div>

            {
                mobileMenuActive &&
                <div className={style.mobileMenu}>
                    <div onClick={onClick} className={style.close_icon}>
                        <div className={style.close}></div>
                    </div>
                    <Navbar style={style.burger_menu} activeStyle={style.active} onClickAction={onClick} visible={true}/>
                    <SocialNavbar style={style.socialNavbarMobile} />
                </div>
            }
        </div>
    )
}

export default MobileNavbar