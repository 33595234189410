import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ImageDetail } from '../components/ImageAndDetails';
import globalStyle from '../styles/Global.module.scss';
import { useRequest } from '../services/RealImageDetailService';
import Title from '../components/Title';
import { ProjectIconDataService } from '../services/ProjectIconDataService';
import parse from 'html-react-parser'

function Work() {
    const location = useLocation();
    const { id } = useParams();
    const [data, setData] = useState<ImageDetail>();
    const [imagePaths, setImagePath] = useState<string[]>([]);
    const { Request, AbortRequest } = useRequest();

    useEffect(() => {
        if (location.state != null) {
            const { details } = location.state;
            setData(details);
            (async () => {
                const data = await Request<string>(`?projectId=${id!}`, `/image/GetImages/`, "GET");
                setImagePath(data);
            })();
        }

        return () => AbortRequest();
    }, []);

    return (
        <>
            <div className={globalStyle.galleryDetails}>
                <Title title={data ? data.title : ""} hasUnderline={false} toUpperCase={false} />
                <div className={`${globalStyle.textSectionDiv} ${globalStyle.galleryTextArea}`}>
                    {data?.info && parse(data.info)}
                </div>
                <span>
                    {data?.icons?.map((icon, index) => <img key={index} className={globalStyle.iconStyle} src={ProjectIconDataService.getData(icon)} alt='' />)}
                </span>
            </div>
            <div className={globalStyle.galleryDetails}>
                {imagePaths.map((imagePath, index) => <img alt='' decoding='async' loading='lazy' className={globalStyle.galleryImage} key={index} src={`../assets/${location.state.path}/${id}/${imagePath}`}></img> )}
            </div>
        </>
    )
}
export default Work