import { useState } from "react";

export interface IFormMessage {
    class: string,
    message: string
}

export const useResponseMessage = () => {
    const [message, setMessage] = useState<IFormMessage>();

    const handleMessage = (message: IFormMessage) => {
        setMessage(message);
    };

    return { message, handleMessage };
}