import React, { useEffect, useState } from 'react';
import AccordionItem from './AccordionItem';
import style from "../styles/Accordion.module.scss"
import { IllustrationType } from '../services/ProjectIconDataService';
import useResizeTracking from '../hooks/ResizeTrackingHook';
import webPageSize from '../types/WindowSize';
import { useLocation } from 'react-router-dom';

//change to type or class?
export interface AccordionData {
    overlayTitle: string,
    overlayInfo: string,
    types: IllustrationType[],
    contentTitle: string,
    content: string,
    imagePath: string
}

//TODO fetch accordionData, move to backend
const accordionData: AccordionData[] = [
    {
        overlayTitle: "JEFFREY THE FOX",
        overlayInfo: "Icon set",
        types: [IllustrationType.Illustrator],
        contentTitle: "JEFFREY The Fox",
        content: "Jeffrey the Fox is a mobile motivational app that uses illustrations and quotes to inspire people to reach their goals. As the creator of these visuals, I am proud to have created over 20 icons and illustrations for this project.\n\nMy work on Jeffrey the Fox has been a lot of fun, and I have put countless hours of hard work and dedication into creating these visuals. From sketches to finished art, I have worked diligently to ensure that my artwork reflects the positive message of this app. I am excited to see how my artwork will help others stay motivated and achieve their dreams.\n\nThe assets are all copyrighted.",
        imagePath: "../assets/accordion/jeffrey_the_fox_IR.webp",
    },
    {
        overlayTitle: "HEARTHSTONE",
        overlayInfo: "Digital illustration",
        types: [IllustrationType.Photoshop],
        contentTitle: "Hearthstone fan art",
        content: "Creating fan art for an online card game like Hearthstone is a great way to express your creativity and show your appreciation for the game. Through my fan art backside card illustration, I wanted to capture the essence of Chtulu and match it to this popular online card game. It was a great challenge to bring together the style of the game and my idea into one piece of artwork.",
        imagePath: "../assets/accordion/hartstone_backside_IR.webp",
    },
    {
        overlayTitle: "DRAGONS",
        overlayInfo: "Digital illustrations",
        types: [IllustrationType.Illustrator],
        contentTitle: "Fruit and Vegetable Dragons",
        content: "Dragons have always been a symbol of power and strength. In this commission, I have taken the concept of dragons to a whole new level by creating over 15 unique fruit and vegetable dragons for an online card game. Each dragon is designed with its own unique characteristics and illustrated with vibrant colors, making them come alive on the cards. With this commission, I have created a world full of powerful yet friendly dragons that will bring joy to players as they embark on their journey in the online card game.\n\nAll illustrations are copyrighted.",
        imagePath: "../assets/accordion/spiff_spinach_dragon_IR.webp",
    },
    {
        overlayTitle: "HELPING THE HOTTIES",
        overlayInfo: "Digital illustration",
        types: [IllustrationType.Photoshop],
        contentTitle: "Belle and Eve - Halloween edition",
        content: "With the xRed game studio, I had the opportunity to create an illustration for an adult novel game. It was a great challenge to capture the essence of the story and bring it to life through my artwork. An exciting project that allowed me to explore my creative side and use my skills as an illustrator in a unique way.\n\nThe XRed game studio is known for its innovative approach to storytelling and creating immersive experiences for players. Working with them on this project allowed me to gain insight into their process and how they approach creating novel games. It was a great experience that has further inspired me in my journey as an illustrator.\n\nThe illustration is copyrighted.",
        imagePath: "../assets/accordion/x_red.webp",
    }
]

function Accordion() {
    const [clicked, setClicked] = useState(-1);
    const { width } = useResizeTracking(250);
    let location = useLocation();

    const handleToggle = (index: number) => {
        if (clicked === index) return setClicked(-1);
        setClicked(index);
    }

    useEffect(() => {
        setClicked(-1);
    }, [location])

    return (
        <>
            {width > webPageSize.mobile && <ul className={`${style.accordion}`}>
                {accordionData.map((data, index) => (
                    <AccordionItem key={index} data={data} onToggle={() => handleToggle(index)} active={clicked === index} />
                ))}
            </ul>
            }
        </>
    );
}

export default Accordion;