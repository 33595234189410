import { useEffect, useMemo, useRef, useState } from "react";

function useResizeTracking(delayMiliseconds: number){
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

    const handleTimerMemo = useMemo(() => {
        return function () {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            }, delayMiliseconds);
        };
    }, [delayMiliseconds]);

    useEffect(() =>{
        window.addEventListener('resize', handleTimerMemo);

        return () => {
            window.removeEventListener('resize', handleTimerMemo);
            clearTimeout(timerRef.current);
        }
    }, [handleTimerMemo]);

    return windowSize;
}

export default useResizeTracking;
