import React, { useCallback, useEffect, useRef, useState } from 'react'
import style from '../styles/Body.module.scss'
import { DynamicViewService } from '../services/IViewService'
import Title from '../components/Title'
import Accordion from '../components/Accordion'
import { useRequest } from '../services/RealImageDetailService'
import { ProjectType } from '../types/ProjectType'
import scrollToTop from '../utility/HelperFunctions'
import getProjectTitle from '../utility/ProjectTitleGenerator'

type UrlScrollPosition = {
    scrollPositionY: number,
    path: number
}



//TODO review this and see if it can be done better, some things are missing, some are not optimized or react best practice
function ProjectsShowcase<T>({ projectType, viewService, active }: { projectType: ProjectType, viewService: DynamicViewService<T>, active: React.Dispatch<React.SetStateAction<boolean>> }) {
    const fetchUrl = useRef("/image/GetCoverImages/");
    const [data, SetData] = useState<T[] | null>(null);
    const { Request, AbortRequest } = useRequest();
    const observer = useRef<ResizeObserver | null>(null);
    const previousLocation = useRef<number>(0);

    const setScrollPosition = useCallback(() => {
        window.localStorage.setItem("scrollPosition", JSON.stringify({ scrollPositionY: window.scrollY, path: projectType }));
    }, []);

    const mapCallback = useCallback((details: T, index: number) => {
        if (index === data!.length - 1) observer.current?.observe(document.getElementById('quePassa')!);
        return viewService.generateViews(details, ProjectType[projectType].toLowerCase(), setScrollPosition);
    }, [data, viewService, setScrollPosition, projectType])

    useEffect(() => {
        (async () => {
            if (data && previousLocation.current === projectType) return;
            const result = await Request<T>(`?type=${projectType}`, fetchUrl.current, "GET");
            SetData(result);
            previousLocation.current = projectType;
            scrollToTop();
        })();
        return (() => {
            AbortRequest();
        });
    }, [fetchUrl, projectType]);

    useEffect(() => {
        active(true);
        return (() => active(false));
    }, [active]);

    useEffect(() => {
        window.history.scrollRestoration = 'manual';
        observer.current = new ResizeObserver(() => {
            const result = window.localStorage.getItem("scrollPosition");
            if (result) {
                const scrollUrlInfo: UrlScrollPosition = JSON.parse(result);
                window.scrollTo(0, scrollUrlInfo.path === projectType ? scrollUrlInfo.scrollPositionY : 0);
            }
            else {
                window.scrollTo(0, 0);
            }
            window.localStorage.removeItem("scrollPosition");
            observer.current!.unobserve(document.getElementById('quePassa')!);
        });

        return (() => {
            window.history.scrollRestoration = 'auto';
        });
    }, []);

    return (
        <>
            <div className={style.bodyStyle}>
                <Title title={getProjectTitle(projectType)} />
                <div id="quePassa" className={`${style.coverGallery}`}>
                    {data && data.map(mapCallback)}
                </div>
            </div>
            <Accordion />
        </>
    )
}
export default ProjectsShowcase