import { MouseEventHandler, useState } from "react";
import accordionStyle from "../styles/Accordion.module.scss"
import bodyStyle from "../styles/Body.module.scss"
import globalStyle from "../styles/Global.module.scss"
import { AccordionData } from "./Accordion";
import Title from "./Title";
import { IllustrationType, ProjectIconDataService } from "../services/ProjectIconDataService";

function AccordionItem({ data, onToggle, active }: { data: AccordionData, onToggle: (MouseEventHandler), active: boolean }) {
    const [isHovering, setHover] = useState(false);

    const toggleHover = (hovering: boolean) => {
        setHover(hovering);
    };

    return (
        <li className={`${accordionStyle.item} ${active ? accordionStyle.active : ""} ${isHovering || active ? accordionStyle.hovering : ""}`}
            onMouseOver={() => toggleHover(true)}
            onMouseOut={() => toggleHover(false)}>
            <div className={`${accordionStyle.visibleContent} ${isHovering || active ? accordionStyle.visibleContentActive : ""}`} onClick={onToggle}>
                <img className={accordionStyle.accordionImage} alt={data.overlayTitle} src={data.imagePath} />
                <div className={`${bodyStyle.overlay} ${accordionStyle.accordionOverlay} ${isHovering || active ? accordionStyle.visibleContentActive : ""}`}>
                    <p className={`${accordionStyle.baseTextStyle}`}>{data.overlayTitle}</p>
                    <p className={`${accordionStyle.baseTextStyle}`}>{data.overlayInfo}</p>
                    {data.types.map(icon => <img key={IllustrationType[icon]} alt={IllustrationType[icon]} className={globalStyle.iconStyle} src={ProjectIconDataService.getData(icon)} />)}
                </div>
            </div>
            <div className={`${accordionStyle.hiddenContent} ${active ? accordionStyle.open : accordionStyle.close}`}>
                <div className={`${accordionStyle.baseContentStyle}`}>
                    <Title title={data.overlayTitle} hasUnderline={false}></Title>
                    <p>{data.content}</p>
                </div>
            </div>
        </li>
    );
};

export default AccordionItem