import React from 'react'
import { Link } from 'react-router-dom'
import style from '../styles/Body.module.scss'
import globalStyle from '../styles/Global.module.scss'
import { IllustrationType, ProjectIconDataService } from '../services/ProjectIconDataService'

//Try not to pass whole data around. Only pass the needed things
export interface ImageDetail {
    id: string,
    coverImagePath: string,
    coverImageInfo: string,
    title: string,
    info: string,
    icons: IllustrationType[]
}

function ImageAndDetail({ details, path, action }: { details: ImageDetail, path?: string, action?: () => void }) {
    return (
        <>
            <Link onClick={action} className={style.linkShowcaseWrapper} to={`/${path}/${details.id}`} state={{ details, path }}>
                <img decoding='async' loading='lazy' className={style.imageShowcase} alt='' src={`${details.coverImagePath}`} />
                <div className={`${style.coverImageOverlay} ${style.overlay}`}>
                    <h3>{details.title.toUpperCase()}</h3>
                    <p>{details.coverImageInfo}</p>
                    <span>
                        {
                            details.icons?.map(icon =>
                                <img key={IllustrationType[icon]} alt={IllustrationType[icon]} className={globalStyle.iconStyle} src={ProjectIconDataService.getData(icon)} />)
                        }
                    </span>
                </div>
            </Link>
        </>
    )
}
export default ImageAndDetail