import Title from './Title';
import { useFormHandleHook } from '../hooks/useFormHandleHook';

export interface ILoginData {
    username: string,
    password: string,
}

//use FormHook instead of this logic
function LoginForm() {
    const { formState, submitting, message, updateForm, submitForm } = useFormHandleHook({username:'', password:''});
    return (
        <div>
            <Title title="Login" />
            {message && <div className={`${message.class}`}>{message.message}</div>}
            <form onSubmit={submitForm}>
                <label>Username<input id='username' autoFocus type="text" value={formState.username} onChange={updateForm}></input></label>
                <label>Password<input id='password' autoFocus type="password" value={formState.password} onChange={updateForm} required></input></label>
                <button disabled={submitting} type="submit">
                    {submitting ? "Trying to log in..." : "Login"}
                </button>
            </form>
        </div>
    )
}
export default LoginForm