import Header from "./components/Header"
import Footer from "./components/Footer"
import globalStyle from './styles/Global.module.scss'
import BodyComponent from "./components/BodyComponent"

function App() {
    return (
        <div className={globalStyle.wrapper}>
            <Header />
            <BodyComponent />
            <Footer />
        </div>
    );
}

export default App;