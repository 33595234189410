import { useState } from "react";

declare global {
  interface Window {
    grecaptcha: any
  }
}

export const useRecaptcha = () => {
  const CAPTCHA_KEY: string = "6LcE9iokAAAAAKF9lIFc4kSTjr2POaGM0b0UuYih"; //move to env params
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

  // IgnoreStrictUseEffect(() => {
  //   if (!recaptchaLoaded && !window.grecaptcha) {
  //     console.log("Called init Captcha");
  //     const externalScript = document.createElement('script');
  //     externalScript.async = true;
  //     externalScript.defer = true;
  //     //externalScript.src = `https://www.google.com/recaptcha/enterprise.js?onload=onLoaded&render=explicit`;
  //     externalScript.src = `https://www.google.com/recaptcha/enterprise.js?render=${CAPTCHA_KEY}`;
  //     externalScript.addEventListener('load', () => { console.log("Recaptcha Loaded"); setRecaptchaLoaded(true); });
  //     document.head.append(externalScript);

  //     return () => {
  //       console.log("Recaptcha unloaded on unmount lifecycle action");
  //       externalScript.remove();
  //       setRecaptchaLoaded(false);
  //     };
  //   }
  // }, []);

  const initCaptcha = () => {
    if (!recaptchaLoaded && !window.grecaptcha) {
      console.log("Called init Captcha");
      const externalScript = document.createElement('script');
      externalScript.async = true;
      externalScript.defer = true;
      //externalScript.src = `https://www.google.com/recaptcha/enterprise.js?onload=onLoaded&render=explicit`;
      externalScript.src = `https://www.google.com/recaptcha/enterprise.js?render=${CAPTCHA_KEY}`;
      externalScript.addEventListener('load', () => {
        console.log("Recaptcha Loaded"); 
        setRecaptchaLoaded(true);
      });
      document.head.append(externalScript);
    }
  }

  const getToken = async (action: string): Promise<string> => {
    let token = "";
    return new Promise((resolve) => {
      window.grecaptcha.enterprise.ready(async () => {
        token = await window.grecaptcha.enterprise.execute(`${CAPTCHA_KEY}`, { action: `${action}` });
        if (token) resolve(token);
        else throw new Error("Error: Did not retrieve token");
      })
    })
  };

  return { getToken, initCaptcha };
}