import { ImageDetail } from "../components/ImageAndDetails";
import { IDataService } from "./IDataService";


//Rework this hook. This is really bad.

export const RealImageDetaiLService: IDataService<ImageDetail> = {
    getData() {
        return [];
    },
}

type requestType = "GET" | "POST" | "DELETE";

//TODO make a new request for each creation of request and return that request as well as its AbortController
//dynamic request based on passed request type as argument, problem with this is that we can make only one request and if we make another during the
//first one the previous one would cancel, it does not matter if the request is of different type
//Request should create a request with an abort signal and return an object that consists of the request and a abort signal to
//abort it if needed. This way we will have a new request instance which will have its own abort function
export function useRequest() {
    let abortController = new AbortController();
    async function Request<T>(data: string | T, url: string, requestType: requestType): Promise<T[]> {
        const requestOptions: RequestInit = {
            body: requestType !== "GET" ? JSON.stringify(data) : null,
            method: requestType,
            headers: { "Content-Type": "application/json" },
            signal: abortController.signal
        }
        
        const response = await fetch(`${url}${requestType === "GET" ? data : ""}`, requestOptions);
        if (!response.ok) throw new Error(`Error! status: ${response.status} \n Error Message: ${response.statusText}`);
        const result = await response.json() as T[];

        return result;
    }

    function AbortRequest() {
        abortController.abort();
        abortController = new AbortController();
    }
    return { Request, AbortRequest }
}
