import { useCallback, useEffect, useRef, useState } from 'react';
import style from '../styles/Parallax.module.scss'

function Parallax() {
    const opacityThreshold = 300;
    const timeoutRef = useRef<number | null>(null);
    const [opacity, setOpacity] = useState(1);

    const handleScroll = useCallback(() => {
        if (timeoutRef.current !== null) {
            window.cancelAnimationFrame(timeoutRef.current);
        }
        timeoutRef.current = window.requestAnimationFrame(() => {
            const newOpacity = Math.max(0, 1 - window.scrollY / opacityThreshold);
            setOpacity(newOpacity);
        });
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("srcoll", handleScroll);
    }, [])

    return (
        <div className={style.paralaxEffect}>
            <div className={style.paralaxContentWrapper} style={{ opacity }}>
                <h1 className={`${style.paralaxContent} ${style.parallaxHeading}`} >{"My art.\nMy world."}</h1>
                <div className={style.paralaxArrowWrapper}>
                    <p className={style.paralaxContent} >{"Scroll for more content"}</p>
                    <div className={`${style.paralaxArrow} ${style.paralaxArrowColor}`}></div>
                </div>
            </div>
        </div>)
}

export default Parallax;