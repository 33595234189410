type windowSize = {
    mobile: number;
    tablet: number;
    desktop: number;
}

const webPageSize: windowSize = {
    mobile: 600,
    tablet: 768,
    desktop: 1200
}

export default webPageSize;