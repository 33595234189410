import React from 'react'
import TextSection, { ITextSection } from '../components/TextSection'
import bodyStyle from '../styles/Body.module.scss'
import parse from 'html-react-parser'

//TODO move to backend
//this data has to be fetched from server and can also be in a form of html so we could use a text editor for it
const textSectionData: ITextSection = {
    title: "About",
    text: `Hello, my name is Iva. I'm a professional Graphic Designer and Digital Artist who loves to think and to create. I’m able to blend my strong illustrative skills with over 10 years worth of graphic design experience. New challenges excite me - I like doing stuff I’ve never done before, in order to keep learning and I try to push the boundaries of innovation, from traditional approaches to new technologies. With my art and design, I strive to be an artist that spreads positivity into the world.

    I have proficiency in working in professional environments as well as having a great passion for art and design, ensuring that the best outcome for a project is achieved. 

    Throughout my career, alongside working in professional environments I have done work for independent companies as well. In digital art, I worked on illustrations for books, board games, card games, and individual commissioned projects. I also worked on visual identities, branding, graphic design, marketing design, and logo design. In games, web and app design, I worked on UI design, game assets, and character designs. My multi-faceted approach to design and art has allowed me to work in a variety of design fields, often combining my skills for a single project, resulting in aesthetic uniformity among every aspect.
    
    Beauty is in the eye of the beholder. Imagination and ingenuity are in the eye of the creator.`
}

const contactText: string = `<p>Let's work together!</br>I'm always happy to collaborate with ambitious brands and people.</br></br>Get in touch at:</br><a href="mailto:irisek.ir@gmail.com">irisek.ir@gmail.com</a></p>`

//text section should be taken from user information from About text details
function About() {
    return (
        <div className={bodyStyle.bodyStyle}>
            <div className={bodyStyle.bodyTextWrapper}>
                <TextSection {...textSectionData} />
                {/* <ContactForm></ContactForm> */}
                <div className={bodyStyle.contactText}>
                    {parse(contactText)}
                </div>
            </div>
        </div>
    )
}
export default About