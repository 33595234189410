import React from 'react'
import LoginForm from '../components/LoginForm'

function Login() {
    return (
        <>
            <LoginForm />
        </>
    )
}
export default Login