import globalStyle from '../styles/Global.module.scss'
import { Route, Routes } from "react-router-dom"
import Parallax from "./Parallax"
import About from "../pages/About"
import Login from "../pages/Login"
import NoPage from "../pages/NoPage"
import ProjectsShowcase from "../pages/ProjectsShowcase"
import Shop from "../pages/Shop"
import Work from "../pages/Work"
import { DynamicImageDetailViewService } from "../services/ImageDetailViewService"
import { ProjectType } from "../types/ProjectType"
import { useState } from "react"

function BodyComponent() {
    const [projectShowcaseActive, setProjectShowcaseActive] = useState(false);

    return (
        <>
            {projectShowcaseActive && <Parallax />}
            <div className={globalStyle.bodyWrapper}>
                <Routes>
                    <Route path="/" element=
                        {
                            <ProjectsShowcase
                                projectType={ProjectType.Illustrations}
                                viewService={DynamicImageDetailViewService}
                                active={setProjectShowcaseActive}
                            />
                        }
                    />
                    <Route path="/gameuidesign" element=
                        {
                            <ProjectsShowcase
                                projectType={ProjectType.GameUIDesign}
                                viewService={DynamicImageDetailViewService}
                                active={setProjectShowcaseActive}
                            />
                        }
                    />
                    <Route path="/projects" element=
                        {
                            <ProjectsShowcase
                                projectType={ProjectType.Projects}
                                viewService={DynamicImageDetailViewService}
                                active={setProjectShowcaseActive}
                            />
                        }
                    />
                    <Route path="/about" element={<About />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/illustrations/:id" element={<Work />} />
                    <Route path="/gameuidesign/:id" element={<Work />} />
                    <Route path="/projects/:id" element={<Work />} />
                    <Route path="*" element={<NoPage />} />
                </Routes>
            </div>
        </>
    )
}

export default BodyComponent