import React from 'react'
import style from '../styles/Title.module.scss'

//refactor to use underline but default it to false
function Title({ title, hasUnderline = true, toUpperCase = true }: { title: string, hasUnderline?: boolean, toUpperCase?: boolean }) {
    return (
        <>
            <h2 className={style.title}>
                {toUpperCase ? title.toUpperCase() : title}
                {/* {hasUnderline && <hr className={style.horizontalLine} />} */}
            </h2>

        </>
    )
}
export default Title