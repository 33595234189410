import React from 'react'
import style from '../styles/Logo.module.scss'
import { NavLink } from 'react-router-dom'
import logo from '../assets/general/ir.png'

function Logo() {
    return (
        <div className={style.logoStyle}>
        <NavLink aria-label='Home' to={'/'}><img className={style.image} src={logo} alt='IR_Logo'/></NavLink>
        </div>
    )
}
export default Logo