export enum IllustrationType {
    Unknown = 0,
    Illustrator = 1,
    Photoshop = 2,
    Inktober2019 = 3,
}

export class ProjectIconDataService{
    private static icons: Map<IllustrationType, any> = new Map([
        [IllustrationType.Illustrator, "../assets/projectIcons/IllustratorIcon.svg"],
        [IllustrationType.Photoshop, "../assets/projectIcons/PhotoshopIcon.svg"],
        [IllustrationType.Unknown, "../assets/projectIcons/UnknownIcon.svg"],
        [IllustrationType.Inktober2019, "../assets/projectIcons/inktober2019Icon.svg"]
    ]);
    public static getData(type: IllustrationType): string {
        const result = this.icons.get(type);
        if(!result) throw new Error(`Path to ${IllustrationType[type]} this type does not exist`);
        return result;
    }
}