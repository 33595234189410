import React from 'react'
import Title from './Title'
import globalStyle from '../styles/Global.module.scss'

export interface ITextSection {
    title: string,
    text: string
}

function TextSection(data: ITextSection) {
    return (
        <div className={globalStyle.textSectionDiv}>
            <Title title={data.title} />
            <p>{data.text}</p>
        </div>
    )
}
export default TextSection