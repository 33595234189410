import { ChangeEvent, useState } from "react";
import { useResponseMessage } from "./useResponseMessage";
import { useRecaptcha } from "./CatpchaHooks";

export const useFormHandleHook = <T extends PropertyKey>(initdata: Record<T, string>) => {
    const [formState, setFormState] = useState(initdata);
    const [submitting, setSubmitting] = useState(false);
    const { message, handleMessage } = useResponseMessage();
    const { getToken } = useRecaptcha();

    const updateForm = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        console.log("Updating form");
        const { id, value } = event.target;
        const updatedFormState = { ...formState };
        updatedFormState[id as keyof Record<T, string>] = value;
        setFormState(updatedFormState);
    }

    const submitForm = async (event: React.FormEvent) => {
        event.preventDefault();
        console.log("Submiting form");
        setSubmitting(true);
        await postSubmision();
        setSubmitting(false);
    }

    const postSubmision = async () => {
        try {
            let token: string = await getToken('submit_form');
            let formTest = {subject:"test", email:"i.horvatin", message:"", name:""};
            const requestOptions: RequestInit = {
                body: JSON.stringify({ recaptchaToken: token, form: formTest }),
                method: "POST",
                headers: { "Content-Type": "application/json" }
            }

            const response:Response = await fetch(`/form/submit`, requestOptions);
            const data = await response.text();
            if (!response.ok) throw new Error(`${data}`);
            console.log(data);
            handleMessage({
                class: "success",
                message: "Message sent successfuly"
            });
            setFormState(initdata);
        } catch (error: any) {
            console.log(error);
            handleMessage({
                class: "error",
                message: "Sending message failed"
            });
        }
    }

    return { formState, submitting, message, updateForm, submitForm } as const;
}