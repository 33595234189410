import { ProjectType } from "../types/ProjectType"

const projectTypeToStringDictionary: { [key in ProjectType]: string } = {
    [ProjectType.Projects]: "Projects",
    [ProjectType.Illustrations]: "Illustrations",
    [ProjectType.GameUIDesign]: "Game UI Design",
};

const getProjectTitle = (projectType: ProjectType): string => {
    var result = projectTypeToStringDictionary[projectType];
    if (!result) {
        console.error(`Project of type ${ProjectType[projectType]} does not exist in dictionary`);
        result = "";
    }

    return result;
}

export default getProjectTitle;