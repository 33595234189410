import { useEffect, useState } from 'react'
import style from '../styles/Footer.module.scss'
import { useRequest } from '../services/RealImageDetailService';

type InstagramMedia = {
    id: string,
    caption: string,
    media_Url: string,
    media_Type: string,
};

type InstagramMediaResponse = {
    data: InstagramMedia[],
    error?: string
}


function InstagramFeed() {
    const [instagramMedia, setInstagramMedia] = useState<InstagramMedia[]>([]);
    // const { Request, AbortRequest } = useRequest();

    useEffect(() => {
        (async () => {
            const requestOptions: RequestInit = {
                body: null,
                method: "GET",
                headers: { "Content-Type": "application/json" }
            }

            const response = await fetch(`/instagram/getallmedia`, requestOptions);
            if (!response.ok) throw new Error(`Error! status: ${response.status} \n Error Message: ${response.statusText}`);
            const result = await response.json() as InstagramMediaResponse;
            if(result.error) console.log(result.error);
            setInstagramMedia(result.data);
        })();
    }, []);

    return (
        <div className={style.instagramFeed}>
            {
                instagramMedia.length > 0 && instagramMedia.map((instagramMedia) => 
                <div className={style.imageWrapper} key={instagramMedia.id}><img alt={instagramMedia.caption} decoding='async' loading='lazy' className={style.galleryImage} src={instagramMedia.media_Url}></img></div>)
            }
        </div>
    )
}
export default InstagramFeed