import React from 'react';
import { ReactComponent as TwitterIcon } from '../assets/socialIcons/twitter_white_500px.svg'
import { ReactComponent as InstagramIcon } from '../assets/socialIcons/instagram_white_500px.svg'
import { ReactComponent as ArtStationIcon } from '../assets/socialIcons/artstation_white_500px.svg'
import { ReactComponent as DribbleIcon } from '../assets/socialIcons/dribbble_white_500px.svg'
import { ReactComponent as FacebookIcon } from '../assets/socialIcons/facebook_white_500px.svg'
import { ReactComponent as YoutubeIcon } from '../assets/socialIcons/youtube_white_500px.svg'

function SocialNavbar({style} : {style:string}) {
    return (
        <>
            <nav className={style}>
                <ul>
                    <li>
                        <a href='https://www.instagram.com/ivarisek_ir/' aria-label='Iva Risek instagram profile' target="_blank"><InstagramIcon /></a>
                    </li>
                    <li>
                        <a href='https://www.artstation.com/ivarisek_ir' aria-label='Iva Risek artstation profile' target="_blank"><ArtStationIcon /></a>
                    </li>
                    <li>
                        <a href='https://dribbble.com/Irisek' aria-label='Iva Risek dribble profile' target="_blank"><DribbleIcon /></a>
                    </li>
                    <li>
                        <a href='https://www.facebook.com/ivarisekIR/' aria-label='Iva Risek facebook profile' target="_blank"><FacebookIcon /></a>
                    </li>
                    <li>
                        <a href='https://twitter.com/IvaRisek_IR' aria-label='Iva Risek twitter profile' target="_blank"><TwitterIcon /></a>
                    </li>
                    <li>
                        <a href='https://www.youtube.com/@ivarisek_ir6987' aria-label='Iva Risek youtube profile' target="_blank"><YoutubeIcon /></a>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default SocialNavbar