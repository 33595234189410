import ImageAndDetail, { ImageDetail } from "../components/ImageAndDetails"
import { DynamicViewService, ViewService } from "./IViewService"

export const ImageDetailViewService: ViewService<ImageDetail> = {
    generateViews(data) {
        return (
            <ImageAndDetail key={data.id} details={data} />
        )
    }
}

export const DynamicImageDetailViewService: DynamicViewService<ImageDetail> = {
    generateViews(data, path, action) {
        return (
            <ImageAndDetail key={`${data.id}`} details={data} path={path} action={action} />
        )
    }
}