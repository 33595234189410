import React from 'react'
import globalStyle from '../styles/Global.module.scss'

interface LinkSectionData {
    text: string,
    url: string,
    method: string,
    name: string,
    imgPath: string,
}

function LinkSection(data: LinkSectionData) {
    return (
        <div className={globalStyle.linkSectionWrapper}>
            <img className={globalStyle.imageStyle} src={data.imgPath} alt={data.name}/>
            <p>{data.text}</p>
            <form action={data.url} target="_blank" method={data.method}>
                <button className={globalStyle.buttonStyle} type="submit">{`Buy on ${data.name.toUpperCase()}`}</button>
            </form>
        </div>
    )
}
export default LinkSection