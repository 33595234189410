import React, { useEffect, useState } from 'react'
import Logo from './Logo'
import style from '../styles/Header.module.scss'
import navbarStyle from '../styles/Navbar.module.scss'
import Navbar from './Navbar'
import { useRecaptcha } from '../hooks/CatpchaHooks';
import { IgnoreStrictUseEffect } from '../hooks/CustomUseEffectHook';
import MobileNavbar from './MobileNavbar';
import useResizeTracking from '../hooks/ResizeTrackingHook'
import webPageSize from '../types/WindowSize'

function Header() {
    //const { initCaptcha } = useRecaptcha();
    const { width } = useResizeTracking(100);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    // IgnoreStrictUseEffect(() => {
    //     initCaptcha();
    // }, []);

    useEffect(() => {
        if (width < webPageSize.mobile) setIsSmallScreen(false);
        else setIsSmallScreen(true);
        return () => {
            setIsSmallScreen(false);
        }
    }, [width])


    return (
        <header id="Header" className={style.headerStyle}>
            <Logo />
            {isSmallScreen ?
                <Navbar style={navbarStyle.navbar} activeStyle={navbarStyle.active} visible={isSmallScreen} />
                :
                <MobileNavbar visible={!isSmallScreen} />
            }
        </header>
    )
}
export default Header