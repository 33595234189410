import React from 'react';
import { NavLink } from 'react-router-dom';

function Navbar({style, activeStyle, visible, onClickAction = undefined}: {style:string, activeStyle:string, visible:boolean,  onClickAction?:React.MouseEventHandler<HTMLAnchorElement>}) {
    const isStyleActive = ({isActive} : {isActive: Boolean}) => {
        return isActive ? activeStyle : undefined;
    }

    return (
        <>
            <nav style={visible ? { visibility: 'visible'} : { visibility: 'hidden'}} className={style}>
                <ul>
                    <li>
                        <NavLink onClick={onClickAction??onClickAction} className={isStyleActive} to="/">Illustration</NavLink>
                    </li>
                    <li>
                        <NavLink onClick={onClickAction??onClickAction} className={isStyleActive} to="/gameuidesign">Game UI Design</NavLink>
                    </li>
                    <li>
                        <NavLink onClick={onClickAction??onClickAction} className={isStyleActive} to="/projects">Projects</NavLink>
                    </li>
                    <li>
                        <NavLink onClick={onClickAction??onClickAction} className={isStyleActive} to="/shop">Shop</NavLink>
                    </li>
                    <li>
                        <NavLink onClick={onClickAction??onClickAction} className={isStyleActive} to="/about">About</NavLink>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default Navbar