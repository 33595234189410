import React from 'react'
import Title from '../components/Title'
import LinkSection from '../components/LinkSection'
import style from '../styles/Body.module.scss'

//TODO move to backend and get data from there if empty?
const Links = [
    {
        id: 1,
        text: "You can find my services on Fiverr.",
        url: "https://www.fiverr.com/ivarisek",
        method: "GET",
        name: "Fiverr",
        imgPath: "../assets/shop/fiverr.png"
    },
    {
        id: 2,
        text: "You can find some of my\nlogo design at LogoGround.",
        url: "https://www.logoground.com/designer.php?did=14540",
        method: "POST",
        name: "Logoground",
        imgPath: "../assets/shop/logoground.png"
    }
]

function Shop() {
    return (
        <div className={`${style.bodyStyle} ${style.contentWrapper}`}>
            <Title title="Shop" />
            <div className={style.shopItems}>
                {Links.map(data => (<LinkSection key={data.id} {...data} />))}
            </div>
        </div>
    )
}
export default Shop